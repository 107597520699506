import React, { createContext, useContext, useState } from 'react';
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle } from '@/components/ui/sheet';

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle
} from '@/components/ui/alert-dialog';

import { Toaster } from '@/components/ui/toaster';
import { useToast } from '@/hooks/use-toast';
import { cn } from '@/lib/utils';


// Types
type SheetOptions = {
  title?: string
  description?: string
  content?: React.ReactNode
}

type AlertOptions = {
  title?: string
  description?: string
  confirmLabel?: string
  cancelLabel?: string
  onConfirm?: () => void
  onCancel?: () => void
  variant?: 'default' | 'destructive' | 'success'
}

type ToastOptions = {
  title?: string
  description?: string
  variant?: 'default' | 'destructive' | 'success'
  duration?: number
}

type UIContextType = {
  // Sheet
  isSheetOpen: boolean
  openSheet: (options: SheetOptions) => void
  closeSheet: () => void
  // Alert Dialog
  isAlertOpen: boolean
  openAlert: (options: AlertOptions) => void
  closeAlert: () => void
  // Toast
  showToast: (options: ToastOptions) => void
}

// Context
const UIContext = createContext<UIContextType | undefined>(undefined);

// Provider Component
export function UIProvider({ children }: { children: React.ReactNode }) {

  const { toast } = useToast();

  // Sheet State
  const [isSheetOpen, setIsSheetOpen] = useState(false);
  const [sheetContent, setSheetContent] = useState<SheetOptions>({});

  // Alert Dialog State
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertContent, setAlertContent] = useState<AlertOptions>({});

  // Sheet Methods
  const openSheet = (options: SheetOptions) => {
    setSheetContent(options);
    setIsSheetOpen(true);
  };

  const closeSheet = () => {
    setIsSheetOpen(false);
    setSheetContent({});
  };

  // Alert Dialog Methods
  const openAlert = (options: AlertOptions) => {
    setAlertContent(options);
    setIsAlertOpen(true);
  };

  const closeAlert = () => {
    setIsAlertOpen(false);
    setAlertContent({});
  };

  // Toast Method
  const showToast = ({
                       title,
                       description,
                       variant = 'default',
                       duration = 3000
                     }: ToastOptions) => {
    toast({
      className: cn(
        'top-0 right-0 flex fixed md:max-w-[420px] md:top-4 md:right-4'
      ),
      title,
      description,
      // variant,
      duration
    });

  };

  return (
    <UIContext.Provider
      value={{
        isSheetOpen,
        openSheet,
        closeSheet,
        isAlertOpen,
        openAlert,
        closeAlert,
        showToast
      }}
    >
      {/* Sheet Component */}
      <Sheet
        open={isSheetOpen}
        onOpenChange={setIsSheetOpen}>
        <SheetContent className='w-full sm:max-w-lg'>
          <SheetHeader>
            {sheetContent.title && <SheetTitle>{sheetContent.title}</SheetTitle>}
            {sheetContent.description && (
              <SheetDescription>{sheetContent.description}</SheetDescription>
            )}
          </SheetHeader>
          {sheetContent.content}
        </SheetContent>
      </Sheet>

      {/* Alert Dialog Component */}
      <AlertDialog
        open={isAlertOpen}
        onOpenChange={setIsAlertOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            {alertContent.title && (
              <AlertDialogTitle>{alertContent.title}</AlertDialogTitle>
            )}
            {alertContent.description && (
              <AlertDialogDescription>
                {alertContent.description}
              </AlertDialogDescription>
            )}
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={alertContent.onCancel}>
              {alertContent.cancelLabel || 'Cancel'}
            </AlertDialogCancel>
            <AlertDialogAction
              onClick={alertContent.onConfirm}
              className={alertContent.variant === 'destructive' ? 'bg-red-600 hover:bg-red-700' : ''}
            >
              {alertContent.confirmLabel || 'Confirm'}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      {/* Toaster Component */}
      <Toaster />

      {children}
    </UIContext.Provider>
  );
}

// Custom Hooks
export function useUI() {
  const context = useContext(UIContext);
  if (!context) {
    throw new Error('useUI must be used within a UIProvider');
  }
  return context;
}
